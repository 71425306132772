@font-face {
	font-family: 'Iosevka Web';
	font-display: swap;
	font-weight: 400;
	font-stretch: normal;
	font-style: normal;
	src: url('fonts/iosevka-regular-subset.woff2') format('woff2')
        /* url('fonts/iosevka-regular.ttf') format('truetype'); */
}

@font-face {
	font-family: 'Iosevka Web';
	font-display: swap;
	font-weight: 200;
	font-stretch: normal;
	font-style: normal;
	src: url('fonts/iosevka-extralight-subset.woff2') format('woff2')
        /* url('fonts/iosevka-extralight.ttf') format('truetype'); */
}
