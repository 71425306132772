@font-face {
  font-family: Iosevka Web;
  font-display: swap;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  src: url("iosevka-regular-subset.c3cc8227.woff2") format("woff2");
}

@font-face {
  font-family: Iosevka Web;
  font-display: swap;
  font-weight: 200;
  font-stretch: normal;
  font-style: normal;
  src: url("iosevka-extralight-subset.e60abb10.woff2") format("woff2");
}
/*# sourceMappingURL=index.3c7d0b82.css.map */
